<template>
  <div class="hold-transition">
    <div
      class="modal-backdrop fade show"
      style="z-index: 10000"
      v-if="cargando"
    >
      <div class="row justify-content-center h-100">
        <div class="align-self-center">
          <ScaleLoader
            class="text-center"
            color="#fff"
            :height="150"
            :width="10"
            margin="10px"
          />
          <h3 class="text-center text-light">Cargando...</h3>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viajes Transporte Fluvial</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Trasnporte Fluvial</li>
                  <li class="breadcrumb-item active">Viajes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="listaSitio">Tipo de sitio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.sitio_type"
                        @change="obtenerSitioType"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="listaSitio in listasForms.listaSitios"
                          :key="listaSitio.numeracion"
                          :value="listaSitio.numeracion"
                        >
                          {{ listaSitio.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="filtros.sitio_type == 1">
                    <label for="sitios">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="slct_sitio"
                      placeholder="Sitio"
                      label="nombre"
                      :options="sitios"
                      :filterable="true"
                      :clearable="true"
                      @input="buildProcess()"
                    ></v-select>
                    <div class="error" v-show="!filtros.sitio_id">
                      Ingrese un sitio
                    </div>
                  </div>
                  <div class="col-md-3" v-if="filtros.sitio_type">
                    <div class="form-group">
                      <label for="listaConsulta">Tipo Consulta</label>

                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_control"
                        @change="getIndex()"
                      >
                        <option
                          v-for="listaConsulta in listasForms.listaConsultas.filter(
                            (item) => item.numeracion === 1
                          )"
                          :key="listaConsulta.numeracion"
                          :value="listaConsulta.numeracion"
                        >
                          {{ listaConsulta.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-md-3 mt-4"
                    v-if="
                      filtros.tipo_control &&
                        filtros.sitio_type &&
                        filtros.sitio_id
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-gradient-primary"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync fa-spin"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="tab-Viajes"
                          data-toggle="tab"
                          href="#Viajes"
                          >Viajes</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import { ScaleLoader } from "@saeris/vue-spinners";

export default {
  name: "ViajesIndex",
  components: {
    ScaleLoader,
    vSelect,
  },
  data() {
    return {
      filtros: {
        sitio_id: null,
        sitio_type: null,
        tipo_control: null,
        sitio_type2: null,
      },
      cargando: false,
      listasForms: {
        listaSitios: [1],
        listaConsultas: [],
      },
      slct_punto: [],
      puntos: [],
      slct_sitio: [],
      sitios: [],
      procesos_sitio: [],
      procesoEnturne: null,
      procesoInspeccion: null,
      procesoValidarInspeccion: null,
      procesoCrearViaje: null,
      procesoCrearViajeRegTerc: null,
      polling: null,
      page: 1,
    };
  },

  methods: {
    async getIndex(page = 1) {},

    async getListaSitio() {
      await axios.get("/api/lista/25").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },
    async getListaConsulta() {
      await axios.get("/api/lista/47").then((response) => {
        this.listasForms.listaConsultas = response.data;
      });
    },

    obtenerSitioType() {
      if (this.filtros.sitio_type == null) {
        this.filtros.sitio_type2 = null;
      }
      if (this.filtros.sitio_type == 1) {
        this.filtros.sitio_type2 = "App\\Sitio";
      }

      if (this.filtros.sitio_type == 2) {
        this.filtros.sitio_type2 = "App\\PuntoGestion";
      }
    },
    async buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista?estado=1";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarPuntos() {
      let me = this;
      var url = "api/admin/puntos_gestion/lista?estado=1";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.puntos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    buildProcess() {
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
        this.procesos_sitio = this.slct_sitio.procesos;
        this.buscarProcesos();
      } else {
        this.filtros.sitio_id = null;
        this.procesos_sitio = [];
        this.$refs.VentanaOperacionesViajes.viajes = {};
        this.$refs.VentanaOperacionesViajes.ventanaEnturnamiento = [];
      }
    },
  },

  async mounted() {
    await this.getListaSitio();
    await this.getListaConsulta();
    await this.buscarSitios();
    await this.buscarPuntos();
    if (
      this.$route.params.sitio_id &&
      this.$route.params.sitio_type &&
      this.$route.params.tipo_control
    ) {
      this.filtros.sitio_id = this.$route.params.sitio_id;
      this.filtros.sitio_type = this.$route.params.sitio_type;
      this.filtros.tipo_control = this.$route.params.tipo_control;

      if (this.filtros.sitio_type == 1) {
        let sitio_n = await this.sitios.find(
          (n) => n.id === parseInt(this.filtros.sitio_id)
        );
        this.slct_sitio = sitio_n;
      }
      if (this.filtros.sitio_type == 2) {
        let sitio_n = await this.puntos.find(
          (n) => n.id === parseInt(this.filtros.sitio_id)
        );
        this.slct_sitio = sitio_n;
      }
      await this.obtenerSitioType();

      await this.getIndex();

      await this.buildProcess();
    }
  },
};
</script>
